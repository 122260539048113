<div class="signin-box-container ui-g">
  <div class="signin-box-inner-container">
    <div class="signin-box ui-md-4 ui-md-offset-4">
      <div class="forgot-pass-form">
        <div class="form-field ui-g-12 ui-g-nopad">
          <div class="form-field ui-g-12 ui-g-nopad text-center">
            <img src='./assets/images/temp/logo-nemp.png'>
          </div>
          <div class="form-field ui-g-12 switch-btn" translate>label_reset_password</div>
          <div class="form-field ui-g-12"></div>
        </div>
        <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
          <form [formGroup]='setPasswordForm'>
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
              <div class="form-label">
                <label for="newPassword" translate>label_new_password</label>
              </div>
              <div class="form-control">
                <input autocomplete="off" class="bottom-border" appNoWhiteSpace type="password"
                  formControlName="newPassword" pInputText />
              </div>
              <div class="form-errors"
                *ngIf="setPasswordForm.controls.newPassword.errors  && (setPasswordForm.controls.newPassword.dirty || setPasswordForm.controls.newPassword.touched)">
                <div [hidden]="!setPasswordForm.controls.newPassword.errors.required" translate>
                  error_new_password_required
                </div>
                <div [hidden]="(!setPasswordForm.controls.newPassword.errors.minlength)
                 && (!setPasswordForm.controls.newPassword.errors.pattern)" translate>
                  error_password_pattern
                </div>
                <div [hidden]="!setPasswordForm.controls.newPassword.errors.maxlength" translate>
                  exceed_max_length
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
              <div class="form-label">
                <label for="newConfirmPassword" translate>label_new_confirm_password</label>
              </div>
              <div class="form-control">
                <input autocomplete="off" class="bottom-border" appNoWhiteSpace type="password"
                  formControlName="newConfirmPassword" pInputText />
              </div>
              <div class="form-errors"
                *ngIf="setPasswordForm.controls.newConfirmPassword.errors  && (setPasswordForm.controls.newConfirmPassword.dirty || setPasswordForm.controls.newConfirmPassword.touched)">
                <div [hidden]="!setPasswordForm.controls.newConfirmPassword.errors.required" translate>
                  error_new_confirm_password_required
                </div>
                <div [hidden]="(!setPasswordForm.controls.newConfirmPassword.errors.minlength)
                 && (!setPasswordForm.controls.newConfirmPassword.errors.pattern)" translate>
                  error_password_pattern
                </div>
                <div [hidden]="!setPasswordForm.controls.newConfirmPassword.errors.maxlength" translate>
                  exceed_max_length
                </div>
              </div>
            </div>

            <div class="ui-g">
              <div class="form-field ui-g-12 UI-MD-12 ui-lg-12">
                <div class="form-errors alert alert-danger" [hidden]='!passwordError[0]["general"]'>
                  <div *ngFor="let ErrorMessage of passwordError['general']">
                    {{ErrorMessage.message}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12">
              <div class="form-buttons ui-g-12 ui-g-nopad">
                <button pButton label="Save" class='signin-save-btn' type="button" [disabled]="!setPasswordForm.valid"
                  (click)="doChangePassword()"></button>
                <button pButton class="cancel-back-btn " type="button" label="Cancel"
                  (click)="onBackSign($event)"></button>
              </div>
            </div>

          </form>
        </div>
        <div class="remove-float"></div>
      </div>

    </div>
  </div>
</div>

<p-growl [value]="message"></p-growl>