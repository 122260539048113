import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class MiTranslateLoaderService implements TranslateLoader {

  getTranslation(lang: string): Observable<any> {
    return of({
      vehicle_type_slatus_change: 'Are you sure you want to {{statusLable}} this Vehicle Type?',
      label_id: 'ID',
      Username: 'Email',
      Password: 'Password',
      exceed_max_length: 'Max length is exceed',
      password_too_short: 'Password is too short',
      comp_name: 'Company Name',
      label_sign_in: 'Sign In',
      label_log_in: 'Login',
      msg_logout: 'Are you sure that you want to perform this action?',
      lbl_confirm_idle_session: `Session has been idle over its time limit,
      It will be disconnected in 2 minutes. Press any key now to continue session.`,
      label_sign_in_welcome_text: 'Welcome to the transport evolution',
      label_nemt_pulse: 'NEMT PULSE',
      label_sign_in_description: 'Powerful dispatching, scheduling and trip management tool for medical transportation',
      label_read_more: 'Read More',
      about_comp: 'About Company',
      label_company_website: 'Company Website',
      label_company_location: 'Company Location',
      label_personal_information: 'Personal Information',
      address: 'Address',
      address_col: 'Address',
      member_address: 'Patient Address',
      rating_col: 'Rating',
      noOfBookings: 'No. of Bookings',
      address_line1: 'Address Line 1',
      address_line2: 'Address Line 2',
      web: 'Website',
      phone: 'Phone Number',
      label_phone: 'Phone',
      label_phone_number: 'Phone Number',
      label_primary_mobile_no: 'Primary Phone Number',
      fax: 'Fax',
      invalid: 'Invalid amount',
      label_amount: 'Amount',
      label_pay: 'Pay',
      invalid_hours: 'Invalid hours',
      invalid_package: 'Invalid route name',
      package: 'Route name required',
      hours: 'Hours required',
      cancellation: 'Cancellation fee required',
      add_hours: 'Additional hours required',
      addKm: 'Additional KM. required',
      base_fare: 'Base fare required',
      driver_percentage_req: 'Driver percentage is required',
      amount_req: 'Amount is required',
      distance_amount: 'Invalid distance amount',
      distance_invalid: 'Invalid distance',
      category: 'Category type is required',
      service: 'Service type is required',
      distance: 'Distance required',
      distance_fare: 'Distance fare required',
      wait_fare: 'Wait time fare required',
      ride_fare: 'Ride time fare required',
      hours_time: 'Hours',
      mins: 'Minutes',
      secs: 'Seconds',
      route_fare: 'Fare required',
      route_radius: 'Radius required',
      route_radius_src: 'Radius required',
      route_dest: 'Destination required',
      pickup_time_validation: 'Please select valid time',
      add_destination_validation: 'Please add Destination',
      please_add_value_to_settings: 'Please add time, 0 is not allowed',
      atleast_one_destination: 'Atleast have one Destination',
      pickup_location_validation_msg: 'Please select proper pickup location',
      pickup_location: 'Pickup location required',
      car_type_req: 'Car type Required',
      hotel_req: 'Hotel Required',
      quotation_amount_req: 'Quotation amount required',
      route_pattern: 'Use numbers only',
      amount_pattern: 'Use numbers with a decimal only',
      amount_pattern_without_zero: 'Use numbers without decimal',
      route_length_src: 'Range 0.1 to 1 KM',
      route_pattern_src: 'Use numbers only',
      invalid_number: 'Invalid Number',
      label_fullname: 'Full Name',
      f_name: 'First Name',
      l_name: 'Last Name',
      label_first_name: 'First Name *',
      label_last_name: 'Last Name *',
      label_phone_num: 'Phone Number *',
      email: 'Email ID',
      label_email: 'Email',
      label_email_id: 'Email',
      label_verify_email: 'Verify Email',
      label_email_imp: 'Email *',
      document_type: 'Document type is required',
      docRegNo: 'Registration No is required',
      label_create_dept: 'Create Department',
      label_edit_dept: 'Edit Department',
      regId: 'Document ID',
      min_length: 'Minimum 3 characters required',
      max_length: 'Exceeded character limit',
      issue_date: 'Date of issue required',
      expiry_date: 'Date of expiry required',
      end_date_required: 'Date of end required',
      start_date: 'Start date is required',
      end_date: 'End date is required',
      document: 'Document required',
      id_num: 'ID Num is required',
      driver_name: 'Driver name is required',
      name_pattern: 'Invalid name',
      pattern_alphabets: 'Use only alphabets',
      phone_invalid: 'Enter a valid phone number',
      number_pattern: 'Use numbers only',
      fax_pattern: 'Invalid fax number',
      email_required: 'Email is required',
      lebel_date_birth: 'Date of Birth',
      dob_required: 'Date of birth is required',
      lebel_gender: 'Gender',
      gender_required: 'Gender is required',
      email_pattern: 'Email ID is invalid',
      address_req: 'Address is required',
      country_req: 'Country is required',
      state_req: 'State is required',
      company_name: 'Company name is required',
      city_req: 'City is required',
      city_pattern: 'Invalid city',
      approval_options: 'Select approval option',
      zipcode_req: 'Zip code is required',
      zipcode_invalid: 'Invalid postal code',
      account_number_invalid: 'Invalid account number',
      banck_code_invalid: 'Invalid account number',
      transmission_type: 'Transmission type required',
      invalid_staff_transport_name: 'Invalid name',
      contact_person_name: 'Contact person name is required',
      label_contact_number: 'Contact Number',
      contact_number_require: 'Phone number is required',
      comp_name_required: 'Company name required',
      about_comp_required: 'About company required',
      website_required: 'Website required',
      phone_required: 'Phone number required',
      min_length_phone_number: 'Phone number should contain minimum 7 digit',
      max_length_phone_number: 'Phone number should contain maximum 13 digit',
      fax_required: 'Fax number required',
      invalid_site: 'Invalid URL',
      service_type: 'Service Type',
      label_service_area: 'Service Area',
      category_t: 'Vehicle Type',
      vehicle_no: 'Vehicle No.',
      vehicle_details: 'Vehicle Make And Model',
      vehicle_not_assigned: 'No vehicle assigned',
      vehicle_details_req: 'Required vehicle make and model',
      vehicle_make: 'Vehicle Make',
      model_yr: 'Model year',
      capacity: 'Capacity',
      lebel_id: 'ID',
      dev_id: 'Device Id',
      condition: 'Vehicle Condition',
      reg_no: 'Registration No.',
      date_issue: 'Date Of Issue',
      date_expiry: 'Date of Expiry',
      label_expired: 'Expired',
      label_empty_message: 'No data found',
      updated: 'Updated On',
      question_req: 'Question required',
      answer_req: 'Answer required',
      hotel_name: 'Hotel name required',
      dispatcher_req: 'Dispatcher required',
      corporate_name: 'Please enter corporate name',
      corporate_code: 'Please enter corporate code',
      name_invalid: 'Please enter valid name',
      corporate_contact_person_name: 'Please enter corporate person name',
      corporateCareExecutive: 'Please select a care executive',
      car_type: 'Car type required',
      drop_location: 'Drop location required',
      booking_date: 'Pickup date required',
      booking_date_and_time_req: 'Pickup date & time is required',
      return_booking_date_and_time_req: 'Return Pickup date & time is required',
      appointment_date_and_time_req: 'Appointment date & time is required',
      passenger_name: 'Passenger name required',
      Package_req: 'Package required',
      cmm_req: 'Car make & model required',
      service_type_req: 'Service Type required',
      max_address: 'Maximum 100 characters allowed',
      max_trip_node: 'Maximum 500 characters allowed',
      note_req: 'Charge code required',
      charge_req: 'Note required',
      deptAdmin_req: 'Assign Department Admin required',
      name_req: 'Name required',
      empId_req: 'Employee id required',
      empId_pattern: 'Invalid Employee id',
      department_req: 'Department required',
      message_passenger_not_assigned: 'Passenger data is not found',
      message_driver_not_assigned: 'Driver not assigned',
      label_date_time: 'Date & time',
      label_date_time_list: 'Date & time',
      label_name: 'Name',
      label_topup: 'Top Up',
      label_successful_trip: 'Successful Trips',
      label_distance_travelled: 'Distance Travelled (Km)',
      label_total_trip_amount: 'Total Trip Amount',
      label_credit: 'Credit',
      label_duty_time: 'Duty Time',
      label_bank_name: 'Bank Name',
      label_bank_name_req: 'Bank name is required',
      label_: '',
      label_average_rating: 'Average Rating',
      label_driver_details_header: 'Driver Details',
      label_passenger_details_header: 'Passenger Details',
      label_status: 'Status',
      label_account_status: 'Account Status',
      label_account_number: 'Account Number',
      label_account_number_req: 'Account number is required',
      label_confirm_account_number: 'Confirm account number',
      label_confirm_account_number_req: 'Confirm account number is required',
      label_bank_code_header: 'Bank Code',
      label_bank_code: 'Bank Code (Routing Number/IFSC)',
      label_bank_code_req: 'Bank code (Routing Number/IFSC) is required',
      label_account_type: 'Account Type',
      label_account_type_req: 'Account type is required',
      label_rental_docs_uploaded: 'Rental Documents Uploaded',
      label_hotel_booking: 'Hotel Bookings',
      label_room_num: 'Room No.',
      label_quotation_amount_accepted: 'Accepted Quotation Amount',
      lable_room_no_req: 'Please Enter Room No.',
      label_booking: 'Bookings',
      label_tripID: 'Trip ID',
      label_balance: 'Balance',
      label_trip_id: 'Trip Id:',
      label_trip_id_text: 'Trip Id',
      label_vehicle_id: 'Vehicle Id',
      label_driver_id: 'Driver Id',
      label_member_id: 'Patient Id',
      label_provider_id: 'Provider Id',
      label_service_id: 'Service Type:',
      label_add_amount: 'Add Top Up Amount',
      label_pickup: 'Pickup',
      label_pickup_header: 'Pick Up',
      label_dropup_header: 'Drop Up',
      label_hotel_succuss: 'Hotel added successfully',
      label_pickup_date_time: 'PickUp Date & Time',
      label_return_pickup_date_time: 'Return PickUp Date & Time',
      label_appointment_date_time: 'Appointment Date & Time',
      label_trip_date: 'Pickup Date and Time',
      label_pickup_latLong: 'Pickup Latitude-Longitude',
      label_appointment_date: 'Appointment Date and Time',
      label_trip_date_time_elapsed: 'Pickup Date and Time is elapsed',
      label_From_date_greater: 'To Date should be greater then From Date',
      label_return_trip_date_time_elapsed: 'Return pickup date & time should be greater than pickup date & time',
      label_return_trip_date_time_elapsed_with_appointment:
        'Return pickup date & time should be greater than pickup date & time and appointment date & time',
      label_appointment_date_time_elapsed: 'Appointment date & time should be greater than pickup date & time',
      label_return_trip_date_time_retype: 'Patient has other trip at same pickup date and time so cannot create the trip',
      label_pickup_date_time_without_col: 'PickUp Date & Time',
      label_no_driver: 'No driver is available',
      label_filter: 'FILTER',
      label_drop_date_time_without_col: 'Drop Date & Time',
      label_vehicle_type: 'Vehicle Type',
      label_vehicle_type_name: 'Vehicle Name',
      label_enter_vehicle_name: 'Enter Vehicle Name',
      label_vehicle_type_header: 'Vehicle Type',
      label_trip_type: 'Trip Type',
      label_doc_expiry_header: 'Docs Expiring in',
      label_vehicle_icon_header: 'Vehicle Icon',
      label_vehicle_type_text: 'Vehicle type',
      label_select_language: 'Select Language',
      noOfPackages: 'No. of Packages',
      label_add_vehicle_type: 'Add Vehicle Type',
      label_edit_vehicle_type: 'Edit Vehicle Type',
      label_change_vehicle_name: 'Change Vehicle Name',
      label_no_of_passenger: `No. of Passenger's `,
      label_view_note_header: 'View Note',
      label_rejected: 'You have rejected',
      label_package: 'Package',
      label_get_estimation: 'Get Estimation',
      label_one_way_estimation: 'Estimation for one way trip',
      label_km: 'Km',
      label_hr: 'Hr',
      label_mins: 'Mins',
      label_previous_quotation: 'Previous Quotations',
      label_service_type_header: 'Service Type',
      label_quoation_amount: 'Quotation amount',
      label_quoation_note: 'Note',
      label_qoutation_reason_for_cancel: 'Reason',
      invalid_amount: 'Invalid amount',
      forgot_password: 'Forgot Password?',
      select_area: 'Select Area',
      label_car_type: 'Car Type',
      label_car_make_model: 'Car make & model',
      label_pickup_location: 'Pickup Location',
      label_drop_location: 'Drop Location',
      label_current_location: 'Current Location',
      label_location: 'Location Name',
      label_location_header: 'Location',
      label_RECUR_AFTER_EVERY: 'RECUR AFTER EVERY',
      label_recurrence_type: 'Please Select  Recurrence Type',
      label_search_by_provider: 'Search By Provider ID Or Name',
      label_search_pickup: 'Search Pickup Location',
      label_search_drop: 'Search Drop Location',
      label_week: 'WEEK',
      lebel_npi: 'NPI',
      label_last_sync: 'Last Synced',
      room_num_max_length: 'Max. 9 character allowed',
      label_member_search: 'Search Patient',
      label_driver_search: 'Search Driver',
      label_passenger_name: 'Passenger Name',
      label_member_name: 'Patient Name',
      label_driver_mobile_no: 'Driver Mobile No.',
      label_mobile: 'Mobile Number',
      label_alternate_number: 'Alternate Number',
      label_assign_driver: 'Assign Driver',
      label_reassign_driver: 'Reassign Driver',
      label_assign_drivers: 'Assign Drivers',
      label_note: 'Note',
      label_packages: 'Packages',
      label_CMM: 'Make',
      label_No_Passenger: 'No Passenger',
      label_from_date: 'From Date',
      label_pickup_from_date: 'Pickup From Date',
      label_from: 'From',
      label_to: 'To',
      label_occurrence: 'Occurrence',
      label_start_date: 'Start Date',
      label_passenger_registered_with: 'Passenger Registered with',
      label_to_date: 'To Date',
      label_pickup_to_date: 'Pickup To Date',
      label_end_date: 'End Date',
      label_start_date_registration: 'Start Date (Registration)',
      label_role: 'Role',
      label_invalid_role: 'Invalid role, contact to server team',
      label_ID_Number: 'ID No.',
      label_address_line_1: 'Address line 1',
      label_address_line_2: 'Address line 2',
      label_address_type: 'Address Type',
      label_country: 'Country',
      label_state: 'State',
      label_city: 'City',
      label_approval_by_admin: 'Approval by Admin',
      label_zip_code: 'Postal Code',
      zip_code: 'Zip Code',
      label_PO_box: 'PO box',
      label_assign_role: 'Assign Role',
      label_No: 'No.',
      label_read: 'READ',
      label_select: 'Select',
      label_select_hotel: 'Select Hotel',
      label_driver_name: 'Driver Name',
      label_driver_email: 'Driver Email',
      label_driver_phone_num: 'Driver Phone No.',
      label_driver: 'Driver',
      label_member: 'Patient',
      label_booking_type: 'Booking Type',
      label_ride_schedule_at: 'Ride Schedule At',
      label_ride_now: 'Ride Now',
      label_ride_later: 'Ride Later',
      label_registration_state: 'Registration State/Province',
      label_registration_state_req: 'Registration State/Province is Required',
      label_booking_service: 'Booking Service',
      label_booking_services: 'Booking Services',
      label_payment_mode: 'Payment Mode',
      label_invoice_date: 'Invoice Date',
      label_invoice_amount: 'Invoice Amount',
      label_refund_date: 'Refund Date',
      label_refund_amount: 'Refund Amount',
      err_refund_exceeds_value: 'Refund value exceeds total amount',
      label_passenger_type: 'Passenger Type',
      label_completed_bookings: 'Completed Bookings',
      label_cancelled_bookings: 'Cancelled Bookings',
      label_active_bookings: 'Active Bookings',
      label_total_amount: 'Total Amount',
      label_payment_received: 'Payment Received',
      label_payment_due: 'Payment Due',
      label_booking_code: 'Booking Code',
      label_booking_count: 'Booking Counts',
      label_waiting_time: 'Waiting Time',
      label_km_in: 'KMs In',
      label_km_covered: ' KM Covered',
      label_km_out: 'KMs Out',
      label_total_vehicles: 'Total Vehicles',
      label_taxi_drivers: 'Taxi Drivers',
      label_rental_car_drivers: 'Rental Car Drivers',
      label_hotel_dispatchers: 'Hotel Dispatchers',
      label_corporate_dispatchers: 'Corporate Dispatchers',
      label_no_of_bookings: 'No. of Bookings',
      label_month: 'Month',
      label_year: 'Year',
      label_transmission_type: 'Transmission Type',
      label_ative_package_info: 'Active Package Information',
      label_expires_today: 'Expires Today',
      label_already_expired: 'Already Expired',
      label_package_not_started: 'Package is not started',
      label_registration_num: 'Registration No.',
      label_date_issue: 'Date of Issue',
      label_date_expiry: ' Date of Expiry',
      label_date_end: ' Date of End',
      label_documents: 'Documents',
      label_document: 'Document',
      label_document_type: 'Document Type',
      label_category: 'Category',
      label_VM: 'Vehicle Make',
      label_vehicle_name: 'Vehicle Name',
      label_VM_name: 'Model Name',
      label_vehicle_no: 'Vehicle No.',
      label_vehicle_reg_no: 'Vehicle Registration Number',
      label_reg_no: 'Registration Number',
      label_reg_no_required: 'Registration number is required',
      label_vehicle_number: 'License Plate Number',
      label_vehicle_number_with_colon: 'License Plate Number :-',
      label_model: 'Model',
      label_model_year: 'Model Year',
      label_model_color: 'Model Color',
      label_residence: 'Residence',
      label_provider: 'Provider',
      label_capacity: 'Capacity',
      label_ETA: 'ETA',
      label_car_condition: 'Car Condition',
      label_device_id: 'Device ID',
      category_req: ' Category Required',
      vehicle_number_req: ' License Plate Number Required',
      vehicle_number_pattern: 'Invalid license plate number',
      vehicle_model_year_req: 'Model year Required',
      vehicle_model_year_pattern: 'Invalid Model year',
      capacity_req: 'Capacity is Required',
      two_digit_max: 'Only 2 digits allowed',
      car_condition_req: 'Car Condition Required',
      device_id_req: 'Device ID Required',
      aplha_numeric_pattern: 'Use only alpha-numeric characters',
      label_dashboard: 'Dashboard',
      label_sales_quotations: 'Sales Quotations',
      label_Passenger_type: 'Type of Passengers',
      label_Passengers: 'Passengers',
      label_Dispatchers: 'Dispatchers',
      label_Vehicles: 'Vehicles',
      label_Vehicle: 'Vehicle',
      label_Drivers: 'Drivers',
      label_Staff_Transport: 'Staff Transport',
      label_hotel: 'Hotel',
      label_Hotels: 'Hotels',
      label_corporate: 'Corporate',
      label_Corporates: 'Corporates',
      label_private: 'Private',
      label_App_Settings: 'App Settings',
      label_Settings: 'Settings',
      label_Reports: 'Reports',
      label_region: 'Region',
      label_reference_setting: 'Reference Setting',
      label_regions: 'Regions',
      label_taxes: 'Taxes',
      admin_settings: 'Admin Settings',
      label_add_region: 'Add Region',
      label_edit_region: 'Edit Region',
      label_title: 'title',
      label_promo_title: 'Promo title',
      lable_promo_title_require: 'Title is required',
      lable_promo_code_require: 'Code is required',
      lable_promo_date_range_require: 'Date range is required',
      lable_usage_require: 'Limit is required',
      lable_usage_pattern: 'invalid limit',
      lable_discount_require: 'Discount is required',
      lable_max_discount_require: 'Max discount is required',
      lable_discount_pattern: 'Invalid Discount',
      lable_max_discount_pattern: 'Invalid max discount pattern',
      lable_already_uploadd_document: 'Document already uploaded for this type.',
      lable_close_package_permision: 'Are you sure do you really want to close this Package?',
      lable_no_vehicle_type_found: 'No Vehicle Type found',
      lable_no_vehicle_found: 'No Vehicle found',
      lable_updated_successfully: 'Updated successfully',
      lable_added_succesfully: 'Added succesfully',
      label_promo_code: 'Promo Code',
      label_no_of_uses: 'No. of uses',
      label_usage_limit: 'Usage Limit',
      label_per_user_usage: 'Usage Limit per User',
      label_add_promo_code: 'Add Promo Code',
      label_update_promo_code: 'Edit Promo Code',
      label_date_range: 'Date Range',
      label_usage: 'Usage',
      label_usage_count: 'Usage Count',
      label_limit_per_user: 'Limit per user',
      label_mode: 'Mode',
      label_discount: 'Discount',
      label_max_discount: 'Max Discount',
      label_time_settings: 'Time Settings',
      label_Pricing: 'Pricing',
      label_Routes: 'Routes',
      label_edit: 'Edit',
      label_update: 'Update',
      label_delete: 'Delete',
      label_add: 'Add',
      label_package_name: 'Package Name',
      label_base_fare: 'Base Fare',
      label_base_fare_placeholder: 'Base Fare *',
      label_booking_fee: 'Booking Fee',
      label_distance: 'Distance',
      label_distance_fare: 'Additional Distance Fare Per KM',
      label_wait_time_fare: 'Wait Time Fare',
      label_wait_time_fare_placeholder: 'Wait Time Fare (Minutes) *',
      label_wait_time_fare_mins: 'Wait Time Fare (Minutes)',
      label_ride_time_fare: 'Ride Time Fare',
      label_cancellation_fee: 'Cancellation Charges',
      label_cancellation_fee_placeholder: 'Cancellation Charges *',
      label_actions: 'Actions',
      label_action: 'Action',
      label_details: 'Details',
      label_create_trip: 'Create Trip',
      label_trip_created: 'Trip Created at',
      label_trip_created_by: 'Trip Created by',
      label_additional_hours: 'Additional Rate Per Hour',
      label_hours: 'Hours',
      label_additional_KM: 'Additional Rate Per KM',
      label_category_type: 'Vehicle type',
      label_distance_unit: 'Distance (KM)',
      label_distance_for_base_fare: 'Distance (KMs) For Base Fare',
      label_send: 'Send',
      label_save: 'Save',
      label_book: 'Book',
      label_cancel: 'Cancel',
      please_select: 'Please Select',
      label_all_cities: 'All Cities',
      label_route_name: 'Route Name',
      label_radius: 'Radius',
      label_radius_m: 'Radius(meters)',
      label_radius_unit: 'Radius(KM)',
      label_no_routes: 'No. of Routes',
      label_updated_on: 'Updated On',
      label_Radius_Meters: 'Radius (Meters)',
      label_source: 'Source',
      label_destination: 'Destination',
      label_add_destination: 'Add Destination',
      label_fare: 'Fare',
      label_fares: 'Fares',
      label_add_route: 'Add Route',
      label_update_route: 'Edit Route',
      label_add_packages: 'Add Packages',
      label_update_packages: 'Update Packages',
      label_FAQ: 'FAQ',
      label_description: 'Description',
      label_description_placeholder: 'Description *',
      label_terms_conditions: 'Terms & Conditions',
      label_privacy_policy: 'Privacy Policy',
      label_rental_privacy_policy: 'Rental Policy',
      label_chauffeur_privacy_policy: 'Chauffeur Policy',
      label_password_match: 'password does not match',
      label_docs_validation: 'Add atleast one document',
      label_otp: 'Please enter OTP',
      label_otp_header: 'OTP',
      label_otp_msg: 'We will send One Time Password (OTP) on your registered email to reset password',
      label_current_km: 'Please enter Current KM',
      label_enter: 'Please enter',
      invalid_otp: 'Invalid OTP',
      verified_otp: 'OTP Verified',
      matched_otp: 'OTP matched',
      label_our_partners: 'Our Partners',
      label_choose: 'Choose',
      label_upload: 'Upload',
      label_upload_trip_data: 'Upload Trip Data',
      label_upload_document: 'Upload Document',
      label_choose_file: 'Choose File',
      label_qusetion: 'Question',
      label_answer: 'Answer',
      label_clear: 'Clear',
      label_edit_term_Condition: 'Edit Terms & Conditions',
      label_edit_privacy_policy: 'Edit Privacy Policy',
      label_edit_rental_policy: 'Edit Rental Policy',
      label_edit_chauffeur_policy: 'Edit Chauffeur Policy',
      label_add_partner: 'Add Partner',
      label_hash: '#',
      label_corporate_info: 'Corporate Info',
      label_contact_person: 'Contact Person',
      label_department_count: 'Total Departments',
      label_registration_Date: 'Registration Date',
      label_total_bookings: 'Total Bookings',
      label_deactivate: 'Deactivate',
      label_activate_edit: 'Active & Edit',
      label_activate: 'Activate',
      label_Total: 'Total',
      label_active: 'Active',
      label_deactive: 'Deactive',
      label_Active: 'active',
      label_Deactive: 'deactive',
      label_cancelled: 'Cancelled',
      label_completed: 'Completed',
      label_new: 'New',
      label_regret: 'Regret',
      label_corporate_information: 'Corporate Information',
      label_package_pricing: 'Package Pricing',
      lable_route_pricing: 'Route Pricing',
      label_corporate_name: 'Corporate Name',
      label_corporate_code: 'Corporate Code',
      label_contact_person_name: 'Contact Person Name',
      label_assign_corporate_executive: 'Assign Corporate Care Executive',
      label_save_next: 'Save & Next',
      label_save_quit: 'Save & Quit',
      label_add_princing: 'Add Pricing',
      label_back: 'Back',
      label_finish: 'Finish',
      label_next: 'Next',
      label_quit: 'Quit',
      label_additional_hours_fee: 'Additional Hours Rate',
      label_hotel_info: 'Hotel Info',
      label_dispatcher_name: 'Dispatcher Name',
      label_dispatcher_no: 'Dispatcher No.',
      label_hotel_name: 'Hotel Name',
      label_dispatcher_details: 'Dispatcher Details',
      label_hotel_information: 'Hotel Information',
      labe_notifications_to: 'to',
      label_notifications: 'Notifications',
      label_no_notification_found: 'No more notification found',
      label_no_driver_found: 'No driver found',
      label_date_and_time: 'Date & time',
      label_resend: 'Resend',
      label_vehicle_info: 'Vehicle info',
      label_vehicle_count: 'Vehicle Count',
      label_assign: 'Assign',
      label_add_documents: 'Add Documents',
      label_add_documents_details: 'Attached Documents Details',
      label_rating: 'Ratings',
      label_avg_rating: 'Avg. Ratings',
      label_yes: 'Yes',
      label_no: 'No.',
      label_all: 'All',
      label_busy: 'Busy',
      label_available: 'Available',
      label_idle: 'Idle',
      label_mailing_address: 'Mailing Address',
      label_off_duty: 'Off Duty',
      label_S_NO: 'S.No.',
      label_vehicle: 'Vehicle',
      label_vehicle_code: 'Vehicle Code',
      label_accesse_name: 'Accesses Name',
      label_card_details: 'Card Details',
      label_dispatcher: 'dispatcher',
      label_trip_completed: 'Trips Completed',
      label_user_type: 'User Type',
      label_vehicleNumber: 'Vehicle Number',
      label_active_vehicle: 'Active Vehicles',
      label_inactive_vehicle: 'Inactive Vehicles',
      label_idle_vehicle: 'Idle Vehicles',
      label_driverID: 'Driver ID',
      label_trip_amount: 'Trip Amount',
      label_bank_details: 'Bank Details',
      label_trip_date_time: 'Trip Date & Time',
      label_BookingId: 'Booking Id',
      label_trip_status: 'Trip Status',
      label_source_address: 'Source Address',
      label_destination_address: 'Destination Address',
      label_reset: 'Reset',
      label_reset_filter: 'Reset Filter',
      label_passenger: 'Passenger',
      label_pickup_drop_location: 'Pickup & Drop location',
      lable_pickup_date: 'Pickup Date',
      lable__date_time: 'Date and Time',
      lable_pickup_date_time: 'Pickup Date and Time',
      lable_select_date_time: 'Select Date & Time',
      lable_select_date: 'Select Date',
      label_booking_ID: 'Booking ID',
      lable_upload_quote_pdf: 'Upload Quote (PDF)',
      label_booking_Date: 'Booking Date',
      label_upload_quote: 'Upload Quote',
      label_start_trip: 'Start Trip',
      label_end_trip_message: 'Trip is ended successfully',
      label_extend_trip: 'Extend Trip',
      label_end_trip: 'End Trip',
      label_cancel_trip: 'Cancel Trip',
      label_security_check: 'Security Check',
      label_delivery_check: 'Delivery Check',
      label_car_return_check: 'Car Return Check',
      label_return_security_check: 'Return Security Check',
      label_resend_quote: 'Resend Quote',
      label_taxi: 'Taxi',
      label_rental_car: 'Rental Car',
      label_chauffeur: 'Chauffeur',
      label_booking_status: 'Booking Status',
      label_payment_status: 'Payment Status',
      label_search: 'Enter text to search.',
      label_search_header: 'Search',
      label_charge_code: 'Charge Code',
      label_department: 'Department',
      label_department_admin: 'Department Admin',
      lable_employeeID: 'Employee ID',
      label_active_trips: 'Active Trips',
      label_completed_trips: 'Completed Trips',
      label_upcoming_trips: 'Upcoming Trips',
      label_critical_trips: 'Critical Trips',
      label_cancelled_trips: 'Cancelled Trips',
      label_driver_availability: 'Driver Availability',
      label_on_trips: 'On Trip',
      label_on: 'ON',
      label_off: 'OFF',
      label_assign_department: 'Assign Department Admin',
      lable_department_name: 'Department Name',
      lable_employee_name: 'Employee Name',
      label_no_trips: 'No. of Trips',
      label_view_details: 'View Details',
      label_mark_vip: 'Mark As VIP',
      label_approve: 'Approve',
      label_reject: 'Reject',
      label_pending_approval: 'Pending for Approval',
      label_manage_employee: 'Manage Employees',
      label_manage_departments: 'Manage Departments',
      label_pricing_details: 'Pricing Details',
      label_taxi_service: 'Taxi Service',
      label_rental_car_service: 'Rental Car Service',
      label_chauffeur_service: 'Chauffeur Service',
      label_start_end_date: 'Start-End Date',
      labee_pickup_time: 'Pickup Time',
      label_staff_transport: 'staff-transport',
      label_day: 'Days',
      label_Assign_vehicle: 'Assign Vehicle',
      label_View_Assign_vehicle: 'View Assign Vehicle',
      label_total_fare: 'Total Fare (TNZ)',
      label_holiday: 'Holidays',
      label_another_drop_location: 'Another Drop Location',
      label_type: 'Type',
      label_view_route: 'View Route info',
      label_route_information: 'Route Information',
      label_renew_package: 'Renew Package',
      label_track_driver: 'Track Driver',
      label_car_no: 'Car No.',
      charge_code_maxlength: 'Allowed maximum 4 character',
      label_Cancelation: 'Cancellation Reasons',
      label_cancellation_reason: 'Cancellation Reason',
      label_booking_cancel: 'Cancel Booking',
      label_rating_comments: 'Rating & Comments',
      label_reply: 'Reply',
      label_cancel_reason: 'Cancel Reason',
      label_reason: 'Reason',
      label_trip_history: 'Trip History',
      label_forgot_password: 'Forgot Password',
      label_forgot: 'User Name/ Email /Phone No.',
      label_validate_otp: 'Validate OTP',
      cancel_reason_req: 'Cancel Reason Required',
      label_cancel_booking: 'Cancel Booking',
      label_country_code: 'Country Code',
      country_code_required: 'Please select country code',
      country_code_invalid: 'Country code is invalid',
      booking_details_result: 'No booking record found',
      booking_comment_details: 'No comment',
      comment_heading: 'Rating & Comments',
      view_all: 'View All',
      label_driver_type: 'Driver Type',
      label_confirmation: 'Confirmation',
      label_select_access: 'Select Access',
      label_confirm_extend_package: 'Do you really want to Extend Package?',
      label_extend_package_confirmation_header: 'Extend Package Confirmation',
      label_confirm_emp_confirm_booking_p1: 'Are you sure that you want to',
      lable_confirm_emp_confirm_booking_p2: ' this booking',
      label_select_service_type: 'Select service type',
      label_invalid_user: 'Invalid User Name/ Email /Phone No.',
      label_driver_location: 'Driver Location',
      label_report_driver_login_logout: 'Driver Login-Logout',
      label_report_driver_on_duty: 'Driver On Duty',
      label_report_driver_income: 'Driver Income',
      label_report_driver_mileage: ' Driver Mileage',
      label_report_driver_performance: 'Driver Performance',
      label_report_car_mileage: 'Car Mileage',
      label_report_transction: 'Transaction',
      label_report_refund: 'Refund',
      label_report_promo_code: 'Promo Code',
      label_report_referral_credit: 'Referral Credit',
      label_report_passenger_feedback: 'Passenger Feedback',
      label_report__feedback: 'Feedback',
      label_mode_promo_code: 'Promo Code Mode',
      label_mode_usage_type: 'Usage Type',
      label_credit_amount: 'Credit Amount',
      label_referred_on: 'Referred on',
      label_clear_polygon: 'Clear Polygon',
      label_region_name: 'Region Name',
      label_alias_name: 'Alias Name',
      label_sr_no: 'Sr No.',
      label_view: 'View',
      label_contact_us: 'Contact Us',
      label_city_regions: 'City Regions',
      label_city_fares: 'City Fares',
      label_edit_fares: 'Edit Fares',
      label_cities: 'Cities',
      label_trip_fare: 'Actual Trip Fare',
      label_qoutation_fare_estimate: 'Trip Fare',
      label_estimated_trip_fare: 'Trip Fare',
      label_estimated_fare: 'Trip Fare',
      label_estimated_distance: 'Estimated Distance',
      label_estimated_time: 'Estimated Time',
      label_total_fares: 'Total Fare',
      label_estimated_total_fares: 'Total Fare',
      label_via_messageService: 'Via MessageService',
      label_service_message: 'Service Message',
      file_size_limit: 'This file is too large. The maximum size allowed is 10MB',
      vehicle_assigned_already: 'Are you sure do you really want to assign this Driver? He is already assigned to other vehicle',
      driver_is_not_assigned: 'Driver is not assigned',
      label_ride_type: 'Ride Type',
      ride_type: 'Ride type is required',
      payment_mode_required: 'Payment mode is required',
      passenger_type_reqd: 'Passenger type is required',
      driver_type_reqd: 'Driver type is required',
      first_name_reqd: 'First name is required',
      last_name_reqd: 'Last name is required',

      // menu handles
      menu_bookings: 'Bookings',
      menu_corporate_bookings: 'Corporate Bookings',
      menu_hotel_bookings: 'Hotel Bookings',
      menu_sales_quotation: 'Sales Quotations',
      menu_admin_dashboard: 'Dashboard',
      menu_corporate_dashboard: 'Corporate Dashboard',
      menu_passengers: 'Passengers',
      menu_dispatchers: 'Dispatchers',
      menu_vehicles: 'Vehicles',
      menu_drivers: 'Drivers',
      menu_staff_transport: 'Staff Transport',
      menu_hotels: 'Hotels',
      menu_corporates: 'Corporates',
      menu_app_settings: 'App Settings',
      menu_settings: 'Settings',
      menu_notifications: 'App Notifications',
      menu_reports: 'Reports',
      menu_corporate_reports: 'Corporate Reports',
      menu_corporate_approval: 'Corporate Approval',
      menu_corporate_pending_approval: 'Pending for Approval',
      menu_corporate_manage_employee: 'Manage Employees',
      menu_corporate_manage_departments: 'Manage Departments',
      menu_corporate_settings: 'Corporate Settings',
      menu_manage_cities: 'Manage Cities',

      // app settings access
      access_get_privacy_policy: 'Get Privacy Policy',
      access_get_contact_support: 'Get Contact Support',
      access_get_rental_policy: 'Get Rental Policy',
      access_getChauffeur_policy: 'Get Chauffeur Policy',
      access_update_about_us: 'Update About Us',
      access_update_terms_and_conditions: 'Update Terms And Conditions',
      access_get_tenant_by_id: 'Get Tenant By Id',
      access_add_faq: 'Add FAQ',
      access_update_faq: 'Update FAQ',
      access_get_faq: 'Get FAQs',
      access_add_partner: 'Add Partner',
      access_update_partner: 'Update Partner',
      access_update_privacy_policy: 'Update Privacy Policy',
      access_delete_partner: 'Delete Partner',
      access_get_partners: 'Get Partners',
      acces_get_service_types: 'Get Service Types',
      access_get_labels: 'Get Labels',
      access_get_service_area: 'Get Service Area',
      access_get_terms_and_conditions: 'Get Terms And Conditions',
      access_get_about_us: 'Get About Us',
      access_udpate_rental_policy: 'Update Rental Policy',
      access_update_chauffer_policy: 'Update Chauffeur Policy',

      // Bookings accesses
      access_get_corporate_booking_grid: 'Get Corporate Bookings Grid',
      access_cancel_trip: 'Cancel Trip',
      access_send_verification_code_to_passenger: 'Send Verification Code To Passenger',
      access_get_booking_bill_details: 'Get Booking Bill Details',
      access_add_driver_trip_emergency: 'Add Driver Trip Emergency',
      access_add_passenger_trip_emergency: 'Add Passenger Trip Emergency',
      access_get_reasons_by_type: 'Get Reasons By Type',
      access_get_corporate_pending_approval_grid: 'Get Corporate Pending Booking Approvals Grid',
      access_approve_booking: 'Approve Booking',
      access_get_booking_estimation: 'Get Booking Estimation',
      access_get_booking_details_by_trip_id: 'Get Booking Details By TripId',
      access_extend_booking_package: 'Extend Booking Package',
      access_udpate_rental_booking_security_checks: 'Update Rental Booking Security Checks',
      access_end_rental_booking: 'End Rental Booking',
      access_get_user_active_bookings: 'Get User Active Bookings',
      access_bookings_export_to_excel: 'Bookings Export To Excel',
      access_add_passenger_trip_rating: 'Add Passenger Trip Rating',
      access_add_driver_trip_rating: 'Add Driver Trip Rating',
      access_add_booking: 'Add Booking',
      access_add_corporate_booking: 'Add Corporate Booking',
      access_add_rental_booking: 'Add Rental Booking',
      access_add_chauffer_booking: 'Add Chaueffer Booking',
      access_get_passenger_bookings_list: 'Get Passenger Bookings List',
      access_get_bookings_by_user_type: 'Get Bookings By User Type',
      access_get_booking_grid: 'Get Booking Grid',
      access_bookings_export_to_pdf: 'Bookings Export To pdf',
      access_get_booking_details: 'Get Booking Details',
      access_cancel_booking: 'Cancel Booking',
      access_assign_driver: 'Assign Driver',
      access_acknowledge_booking_by_driver: 'Acknowledge Booking By Driver',
      access_accept_trip: 'Accept Trip',
      access_start_trip: 'Start Trip',
      access_end_trip: 'End Trip',
      access_update_booking_bill_cash: 'Update Booking Bill Cash',
      access_update_booking_bill_credit_note: 'Update Booking Bill Credit Note',
      access_update_booking_driver_arrived: 'Update Booking Driver Arrived',
      access_add_trip_comment_reply: 'Add Trip Comment Reply',
      access_get_note_by_trip_id: 'Get Note By TripId',
      resend_notification_message: 'Do you really want to resend the notification?',
      delete_faq: 'Are you sure you want to delete the FAQ?',
      delete_partner: 'Are you sure you want to delete this partner?',
      action_note1: 'Are you sure you want to ',
      corporate_action_note2: ' this Corporate?',
      dispatcher_action_note2: '  this dispatcher ?',
      invalid_corporate_message: 'Unable to load corporate details',
      edit_company_info: 'Edit Company Info',

      // corporate booking approval
      access_get_pending_approvals_grid: 'Get Pending Approvals Grid',
      access_approva_reject_corp_booking: 'Approve/Reject Corporate Booking',
      access_get_Corporate_booking_details: 'Get Corporate Booking Details',
      access_get_charge_codes_for_corporate: 'Get Charge Codes For Corporate',
      access_get_corporates_and_dept: 'Get Corporates And Departments',
      // corporate dashboard
      access_get_emergency_details: 'Get Emergency Details',
      access_get_corp_dash_emer_grid: 'Corporate Dashboard Emergencies Grid',
      access_get_corp_dash_graph: 'Get Corporate Dashboard Graph Data',
      access_get_corporate_dash: 'Get Corporate Dashboard Data',
      // corporate
      access_corp_count: 'Corporate Count',
      access_corp_search: 'Search Corporate',
      access_member_search: 'Search Patient',
      access_member_search_id: 'Search Patient by ID',
      access_member_search_neme: 'Search Patient by Name',
      access_driver_search_neme: 'Search Driver by Name',
      access_corp_export_to_pdf: 'Corporate Export To PDF',
      access_corp_export_to_xls: 'Corporate Export To Excel',
      access_corp_activate: 'Activate Corporate',
      access_corp_and_dept: 'Get Corporates And Departments',
      access_corp_dept_by_code: 'Get Corporate Departments By Corporate Code',
      access_corp_add: 'Add Corporate',
      access_corp_details: 'Get Corporate Details',
      access_corp_update: 'Update Corporate',
      access_corp_grid: 'Get Corporate Grid',
      access_corp_booking_history: 'Corporate Booking History',
      // corporate settings
      access_corp_setting_get_charge_code: 'Get Charge Codes For Corporate',
      access_corp_setting_charge_code_grid: 'Charge Code Grid',
      access_corp_setting_add_chage_code: 'Add Charge Code',
      access_corp_setting_routes_grid: 'Routes Grid',
      access_corp_setting_partner_packge_grid: 'Partner Packages Grid',
      // dashboard
      access_admin_dash_emer_and_booking_details: 'Emergency and booking details',
      access_admin_dash_emer_grid: 'Emergency Grid',
      access_admin_dash_get: 'Get Dashboard Data',
      access_admin_dash_get_graph: 'Get Dashboard Graph Data',
      label_manual_bookings: 'Manual Bookings',
      label_manual_booking_count: 'Total Manual Bookings',
      label_app_bookings: 'App Bookings',
      label_count_app_bookings: 'Total App Bookings',
      // dispatchers
      access_disp_get_details: 'Get Dispatcher Details',
      access_disp_update: 'Update Dispatcher',
      access_disp_export_to_pdf: 'Dispatchers Data For Export To PDF',
      access_disp_export_to_xls: 'Dispatchers Data For Export To Excel',
      access_disp_get_count: 'Get Dispatchers Count',
      access_disp_grid: 'Dispatchers Grid',
      access_disp_search: 'Search Dispatchers',
      access_disp_get_booking_history: 'Get Dispatcher Booking History',
      access_disp_get_roles: 'Get Dispatcher Roles',
      access_disp_activate_deactivate: 'Activate/Deactivate Dispatcher',
      access_disp_add: 'Add Dispatcher',
      // drivers
      access_driver_get: 'Get Driver Info',
      access_driver_update: 'Update Driver',
      access_driver_search: 'Search Driver',
      access_driver_activate_deactivate: 'Activate/Deactivate Driver',
      access_driver_grid: 'Drivers Grid',
      access_driver_export_to_xls: 'Drivers Export To Excel',
      access_driver_export_to_pdf: 'Drivers Export To PDF',
      access_driver_get_count: 'Get Drivers Count',
      access_driver_get_vehicle_and_assigne: 'Get Vehicle Assigned To Driver',
      access_driver_update_duty_status: 'Update Driver Duty Status',
      access_driver_get_near_by: 'Get Near By Drivers',
      access_driver_get_booking_history: 'Get Driver Booking History',
      access_driver_get_location: 'Get Location Of Driver',
      access_driver_get_docs_types: 'Get Driver Document Types',
      access_driver_add: 'Add Driver',
      label_service_cities: 'Service Cities',
      label_service_city: 'Service City',
      // hotel bookings
      access_hotel_bookings_grid: 'Hotel Bookings Grid',
      access_hotel_bookings_add: 'Add Hotel Bookings',
      access_hotel_bookings_export_to_xls: 'Hotel Bookings Export To Excel',
      access_hotel_bookings_export_to_pdf: 'Hotel Bookings Export To PDF',
      access_hotel_bookings_list: 'Get All Hotels List',
      // hotels
      access_hotels_activate: 'Activate/Deactivate Hotel',
      access_hotels_booking_grid: 'Get Hotel Booking History Grid',
      access_hotels_grid: 'Hotels Grid',
      access_hotels_export_to_xls: 'Hotels Export To Excel',
      access_hotels_export_to_pdf: 'Hotels Export To PDF',
      access_hotels_get_count: 'Get Hotels Count',
      access_hotels_search: 'Search Hotel',
      access_hotels_add: 'Add Hotel',
      access_hotels_details: 'Get Hotel Details',
      access_hotels_update: 'Update Hotel',
      // manage departments
      access_dept_update: 'Update Department',
      access_dept_add: 'Add Department',
      access_dept_export_to_xls: 'Departments Grid Export To Excel',
      access_dept_grid: 'Departments Grid',
      access_dept_search: 'Search Employee',
      // manage employee
      access_emp_add: 'Add Employee',
      access_emp_trip_history_grid: 'Employee Trip History Grid',
      access_emp_set_vip: 'Set Employee As VIP',
      access_emp_reject: 'Reject Employee',
      access_emp_approve: 'Approve Employee',
      access_emp_export_to_xls: 'Employees Grid Export To Excel',
      access_emp_activate: 'Activate/Deactivate Employee',
      access_emp_for_pending_approval: 'Get Employee For Pending Approval',
      access_emp_details: 'Get Employee Details',
      access_emp_udpate: 'Update Employee',
      access_emp_grid: 'Employees Grid',

      // notifications
      access_noti_send: 'Send Notification',
      access_noti_delete_of_user: 'Delete Notifications Of User',
      access_noti_delete: 'Delete Notification',
      access_noti_mark_read: 'Mark Notification Read',
      access_noti_send_status: 'Send Notification Status',
      access_noti_get_list: 'Get Notifications List',

      // passengers
      access_passengers_get_details: 'Get Passenger Details',
      access_passengers_get_trip_history: 'Get Trip History For Passenger',
      access_passengers_get_by_type: 'Get Passengers By Type',
      access_passengers_search: 'Search Passengers',
      access_passengers_grid: 'Passenger Grid',
      access_passengers_get_count: 'Get Passenger Count',
      access_passengers_export_to_xls: 'Passenger Data Export To Excel',
      access_passengers_export_to_pdf: 'Passenger Data Export To PDF',
      access_passengers_activate: 'Active/Deactive Passenger',
      // sales quotations
      access_sales_quo_accept_reject: 'Accept/Reject Sales Quotation',
      access_sales_quo_upload: 'Upload Sales Quotation',
      access_sales_quo_details: 'Sales Quotation Details',
      access_sales_quo_grid: 'Sales Quotations Grid',
      access_sales_quo_cancel: 'Cancel Sales Quotation',
      access_sales_quo_export_to_xls: 'Sales Quotations Export To Excel',
      access_sales_quo_export_to_pdf: 'Sales Quotations Export To PDF',
      access_sales_quo_view_quotations_details: 'View Quotation Details',

      // staff transport
      access_staff_update: 'Update Staff Transport',
      access_staff_activate: 'Activate/Deactivate Company',
      access_staff_get_drop_points: 'Get Staff Transport Vehicle Drop Points',
      access_staff_add: 'Add Staff Transport',
      access_staff_grid: 'Staff Transport Grid',
      access_staff_details: 'Get Staff Transport Details',
      access_staff_activate_vehicle: 'Activate/Deactivate Staff Transport Vehicle',
      access_staff_count: 'Get Staff Transport Count',
      access_staff_add_booking: 'Add Booking For Staff Transport',
      access_staff_export_to_xls: 'Staff Transport Export To Excel',
      access_staff_export_to_pdf: 'Staff Transport Export To PDF',
      access_staff_update_status: 'Update Staff Transport Status',
      access_staff_renew_package: 'Renew Staff Transport Package',

      // vehicles
      access_vehicle_get_by_type: 'Get Vehicle By Vehicle Type',
      access_vehicle_get_types: 'Get Vehicle Types',
      access_vehicle_get_service_type: 'Get Service Types',
      access_vehicle_activate: 'Activate/Deactivate Vehicle',
      access_vehicle_grid: 'Vehicles Grid',
      access_vehicle_get_docs_types: 'Get Vehicle Document Types',
      access_vehicle_add: 'Add Vehicle',
      access_vehicle_get_count: 'Get Vehicle Counts',
      access_vehicle_update: 'Update Vehicle',
      access_vehicle_get_details: 'Get Vehicle Details',
      access_vehicle_export_to_xls: 'Vehicles Data Export To Excel',
      access_vehicle_export_to_pdf: 'Vehicles Data Export To PDF',
      access_vehicle_search: 'Search Vehicle',
      access_vehicle_near_by_vehicles: 'Get Near By Vehicles',
      access_vehicle_get_driver_info_by_vehicle: 'Get Driver Info By Vehicle',
      access_vehicle_get_near_by_vehicles_by_area: 'Get Near By Vehicles By Area',
      access_vehicle_booking_history_grid: 'Vehicle Booking History Grid',
      access_vehicle_add_type: 'Add Vehicle Type',
      access_vehicle_update_type: 'Update Vehicle Type',
      access_vehicle_type_grid: 'Vehicle Types Grid',
      access_vehicle_activate_type: 'Activate/Deactivate Vehicle Type',
      cnf_deactivate_city_then_all_driver_not_receive_request:
        'If you deactivate city then all drivers attached to this city will not receive any trip requests',
      cnf_realy_want_to: 'Are you sure you want to ',
      cnf_this_area: ' this area?',
      cnf_this_company: ' this company?',
      cnf_this_region: ' this region?',

      // cities
      label_city_name: 'City Name',
      lable_alias_name: 'Alias Name',
      label_add_city: 'Add City',
      label_city_name_msg: 'City Name required',
      label_alias_msg: 'Alias Name required',
      label_last_hour: 'Last Hour',
      label_last_4_hours: 'Last 4 Hours',
      label_current_day: 'Current Day',
      label_last_7_days: 'Last 7 Days',
      label_last_15_days: 'Last 15 Days',
      label_last_30_days: 'Last 30 Days',
      label_from_custom_date: 'Custom Date',
      label_today: 'Today',
      label_yesterday: 'Yesterday',
      label_this_month: 'This Month',
      label_last_month: 'Last Month',
      label_export: 'Export',
      label_total_trips_cancelled_by_drivers: 'Total Trips Cancelled By Drivers',
      label_cancelled_by: 'Cancelled By',
      label_total_trips: 'Total Trips',
      label_trips: 'Trips',
      label_total_trips_cancelled_by_passengers: 'Total Trips Cancelled By Passengers',
      label_total_drivers_on_platform: 'Total Drivers On Platform',
      label_drivers_received_trips: 'Drivers Received Trips',
      label_total_drivers_payout: 'Total Drivers Payout',
      label_total_platform_commission: 'Total Platform Commission',
      label_total_receivable_from_drivers: 'Total Receivable From Drivers',
      label_total_promo_discount: 'Total Promo Discount',
      label_total_refunded_amount: 'Total Refunded Amount',
      label_view_report: 'View Report',
      label_role_name: 'Role Name',
      role_name_pattern: 'Invalid role name',
      label_role_code: 'Role Code',
      label_user_code: 'User Code',
      label_user_name: 'User Name',
      label_tax_name: 'Tax Name',
      label_tax_percentage: 'Tax Percentage',
      label_free_distance: 'Free Distance',
      label_free_distance_placeholder: 'Free Distance (KM) *',
      free_dist_req: 'Free distance is required',
      label_per_distance_fare: 'Per Miles Fare',
      label_per_distance_fare_placeholder: 'Per KM Fare',
      label_per_Mile_fare: 'Per Mile Fare',
      label_per_min_fare: 'Per min. Fare',
      label_per_min_fare_placeholder: 'Per min. Fare *',
      label_driver_percentage: 'Driver Percentage',
      label_driver_percentage_placeholder: 'Driver Percentage *',
      label_contactNumber: 'Contact Number',
      label_supportEmail: 'Support Email',
      label_minimum_fare: 'Minimum Fare',
      label_minimum_fare_placeholder: 'Minimum Fare *',
      minimum_fare_req: 'Minimum fare is required',
      label_referrer_benefit: 'Referrer Benefit',
      label_referee_benefit: 'Referee Benefit',
      have_promo_code: 'Have A Promotional Code?',
      label_manual_booking: 'Manual Booking',
      label_book_now: 'Book Now',
      label_book_ride_now: 'Book Ride Now',
      label_book_ride_later: 'Book Ride Later',
      label_book_ride_share: 'Book Ride Share',
      label_ride_share: 'Ride Share',
      label_car_details: 'Car Details',
      label_trip_details: 'Trip Details',
      label_car_type_with_colon: 'Car Type :-',
      label_model_with_colon: 'Model Name :-',
      label_vehicle_make_with_colon: 'Vehicle Make :-',
      label_color_with_colon: 'Color :-',
      label_vehicle_type_with_colon: 'Vehicle Type :-',
      label_plate_number_with_colon: 'Plate Number :-',
      label_status_with_colon: 'Status',
      label_email_text: 'Email',
      label_color: 'Color',
      label_refund: 'Refund',
      label_rider_details: 'Rider Details',
      label_vehicle_details: 'Vehicle Details',
      label_rating_given_by_driver: 'Ratings given by driver',
      label_rating_given_by_passenger: 'Ratings given by passenger',
      label_fare_breakdown: 'Fare Breakdown',
      label_trip_statistics: 'Trip Statistics',
      label_distance_fare_text: 'Distance fare',
      label_time_fare: 'Time fare',
      label_total: 'Total',
      label_duration: 'Duration',
      label_total_time: 'Total time',
      label_email_invoice: 'Email Invoice',
      label_invoice_receipt: 'Invoice Receipt',
      label_cash_collected: 'Cash Collected',
      label_profile_password: 'Profile Password',
      label_edit_profile: 'Edit Profile',
      label_password: 'Password',
      label_profile_password_required: 'Profile password is required',
      label_invalid_amount: 'Invalid amount',
      refund_amount_required: 'Refund amount is required',
      label_free_cars: 'Free Vehicles',
      label_busy_cars: 'Busy Vehicles',
      label_idle_cars: 'Idle Vehicles',
      label_no_nearby_driver: 'It seems there are no nearby drivers available',
      label_tour_time_fare: 'Trip Time Fare',
      label_enroute_waiting_fare: 'Enroute Waiting Fare',
      label_manage_taxes: 'Manage Taxes',
      label_add_tax: 'Add Tax',
      label_update_tax: 'Update Tax',
      label_app_store_url: 'App Store URL',
      label_play_store_url: 'Play Store URL',
      label_facebook_url: 'Facebook URL',
      label_twitter_url: 'Twitter URL',
      label_google_plus_url: 'Google Plus URL',
      label_number_of_seats: 'Number Of Seats',
      label_number: 'Number',
      label_additional_seat_fare: 'Additional Seat Fare',
      label_ride_share_service: 'Ride Share Service',
      label_profile_updated_successfully: 'User profile is updated Successfully',
      label_load_more_notifications: 'Load More Notifications',
      label_show_more: 'Show More..',
      label_map: 'Map',
      label_heat_map: 'Heat Map',
      label_plateform_summary_report: 'Platform Summary Report',
      label_emergency: 'Emergency',
      label_driver_emergency: 'Driver Emergency',
      label_passenger_emergency: 'Passenger Emergency',
      label_member_emergency: 'Patient Emergency',
      label_by_drivers: 'By Drivers',
      label_by_members: 'By Patients',
      label_new_request: 'New Request',
      label_attened: 'Attended',
      label_attend_it: 'Attend It',
      label_closed: 'Closed',
      label_close_it: 'Close It',
      label_request_status: 'Request Status',
      label_arrange_vehicles: 'Arrange Vehicles',
      label_all_vehicle_type: 'All Vehicle Type',
      label_selected_vehicle_type: 'Selected Vehicle Type',
      label_company_name: 'Company Name',
      label_company: 'Company',
      company_pattern: 'Invalid company name',
      label_add_company: 'Add Company',
      label_edit_company: 'Edit Company',
      label_change_password: 'Change Password',
      label_appointment_type: 'Appointment Type',
      label_cancelled_trip_reason: 'Cancelled Trip Reason',
      label_appointment_reason: 'Appointment Reason',
      label_member_requirment: 'Patient Requirement',
      label_member_details: 'Patient Details',
      label_additional_notes: 'Instructions',
      label_recurrence_pattern: 'Recurrence Pattern',
      label_range_of_recurrence: 'Range of recurrence',
      label_additional_notes_placeholder: 'Ex: Drive slow',
      label_old_password: 'Old Password *',
      label_new_password: 'New Password *',
      label_new_confirm_password: 'Confirm New Password *',
      label_otp_send_text: 'Please enter OTP which has been send to your Email ID',
      label_add_user: 'Add User',
      label_edit_user: 'Edit User',
      label_add_operator: 'Add Operator',
      label_edit_operator: 'Edit Operator',
      label_operator_name: 'Operator Name',
      label_reset_password: 'Reset Password',
      label_select_no_of_seats: 'Select Number Of Seat(s)',
      label_vehicle_assigned: 'Vehicle Assigned',
      label_assigned: 'Assigned',
      label_non_assigned: 'Non-Assigned',
      label_unassigned: 'Unassigned',
      label_admin_settlement: 'Admin Settlement',
      label_promo_discount: 'Promo Discount',
      label_credits: 'Credits',
      label_percentage: 'Percentage',
      label_driver_amount: 'Driver Amount',
      label_date: 'Date',
      label_bid_amount: 'Bid Amount',
      label_driver_status: 'Driver Status',
      label_passenger_status: 'Passenger Status',
      label_bidding_details: 'Bidding Details',
      label_total_invoice: 'Total Invoice',
      label_booking_fares: 'Booking Fare',
      label_total_distance: 'Total Distance',
      label_total_driver: 'Total Drivers',
      label_active_driver: 'Active Drivers',
      label_inactive_driver: 'Inactive Drivers',
      label_offline_driver: 'Offline Drivers',
      label_online_driver: 'Online Drivers',
      label_doc_expiring: 'Docs Expiring In 7 Days',
      label_total_passengers: 'Total Passengers',
      label_online_passengers: 'Online Passengers',
      label_online_vehicles: 'Online Vehicles',
      label_total_dispatchers: 'Total Dispatchers',
      label_online_dispatchers: 'Online Dispatchers',
      label_total_hotels: 'Total Hotels',
      label_online_hotels: 'Online Hotels',
      label_total_companies: 'Total Companies',
      label_online_companies: 'Online Companies',
      label_total_corporates: 'Total Corporates',
      label_online_corporates: 'Online Corporates',
      label_ride_share_name: 'Ride Share Name',
      label_source_radius: 'Source Radius(miles)',
      label_destination_radius: 'Destination Radius(miles)',
      label_per: 'Per',
      label_source_radius_dynamic: 'Source Radius',
      label_destination_radius_dynamic: 'Destination Radius',
      label_no_of_drivers: 'No. of Drivers',
      label_create_segment: 'Create Segment',
      label_create_notification: 'Create Notification',
      label_segment_name: 'Segment Name',
      label_segment: 'Segment',
      label_created_date_time: 'Created Date & Time',
      label_created_date_and_time: 'Created Date and Time',
      label_created: 'Created',
      label_contact_match: 'Contact match your condition',
      label_role_type: 'Role Type',
      label_radius_area: 'KM *',
      label_near_by_driver: 'Near by Drivers',
      label_user: 'User',
      label_segment_required: 'Segment Name is required',
      label_send_notification: 'Send Notification',
      label_message_type: 'Message Type',
      label_select_message_type: 'Select Message Type',
      label_message: 'Message',
      label_message_title: 'Message Title',
      label_segment_list: 'Segment List',
      label_select_segment: 'Select Segment',
      label_add_new_segment: 'Add new Segment',
      label_message_description: 'Message Description',
      label_message_description_place: 'Message Description (Maximum characters limit: 150)',
      label_message_email_description_place: 'Message Description (Maximum characters limit: 150)',
      label_add_segment: 'Add Segment',
      mins_required: 'Minutes required',
      secs_required: 'Seconds required',
      label_promocode_pattern: 'Promocode should be in capital and numbers only',
      label_driver_information: 'Driver Information',
      label_vehicle_information: 'Vehicle Information',
      label_member_information: 'Patient Information',
      label_distance_travelled_miles: 'Distance Travelled',
      label_distance_travelled_miles_KM: 'Distance Travelled( KM )',
      label_fare_details: 'Fare Details',
      label_current_driver_online: 'Current Driver Online',
      label_valid_date_range: 'Please Select valid Date range for reports',
      label_offline: 'Offline',
      label_vehicle_make_model: 'Vehicle make & model',
      label_driver_required: 'Driver required',
      label_drop_trip: 'Drop Trip',
      label_drop_time_required: 'Drop time required',
      label_total_time_in_minuites: 'Trip Time',
      label_no_route_available: 'No Route Available',
      label_referral_code: 'Referral Code',
      label_wallet_amount: 'Wallet Amount',
      label_trip_note: 'Trip Note',
      label_add_note: 'Add Note',
      label_wallet_update: 'Wallet Update',
      label_amount_required: 'Amount required',
      label_amount_limit_exceeded: 'Amount limit exceeded',
      label_wallet: 'Wallet',
      label_referral_credit: 'Referral Credit',
      label_payment_type: 'Payment Type',
      label_tour_start_date_time: 'Tout Start Date & Time',
      label_tour_end_date_time: 'Tout End Date & Time',
      label_tour_status: 'Trip Status',
      label_service_type_code: 'Service Type Code',
      lbl_credit_card_info: 'Card Info',
      label_sub_total: 'Sub Total',
      label_tax_amount: 'Tax Amount',
      label_grand_total: 'Grand Total',
      label_vehicle_condition: 'Vehicle condition',
      label_company_percentage: 'Company Percentage',
      label_company_percentage_placeholder: 'Company Percentage *',
      label_total_completed_trips: 'Total completed Trips',
      label_total_invoice_amount: 'Total Invoice Amount',
      label_company_share: 'Company Share',
      label_admin_share: 'Admin Share',
      label_add_vehicle: 'Add Vehicle',
      label_add_driver: 'Add Driver',
      label_remove_vehicle: 'Remove Vehicle',
      label_add_vehicle_description: 'Please select one or more vehicle(s)',
      label_view_assign_vehicle: 'View Assigned Vehicle',
      label_vehicle_not_found: 'No assigned vehicle found for this driver',
      label_draw_region_on_map: 'Please draw region on map.',
      label_select_valid_app_user: 'Please select valid app user.',
      label_fillup_guest_user_details: 'Please fill up guest user details.',
      label_select_driver_from_driver_list: 'Please select driver from driver list.',

      // Error messages
      file_upload_error: 'Entity is too large to upload',
      error_region_name_required: 'Region Name is required',
      error_country_code: 'Please select country code',
      error_alias_name_required: 'Alias Name is required',
      error_cities_required: 'City is required',
      error_service_area_required: 'Service area is required',
      error_base_fare_required: 'Base fare is required',
      error_invalid_base_fare: 'Invalid base fare',
      error_booking_fee_required: 'Booking fee is required',
      error_invalid_booking_fee: 'Invalid booking fee',
      error_ride_time_fare_required: 'Ride time fare is required',
      error_invalid_ride_time_fare: 'Invalid ride time fare',
      error_vehicle_required: 'Vehicle is required',
      error_about_us_required: 'About us is required',
      error_model_name_required: 'Model name is required',
      error_model_color_required: 'Model color is required',
      error_vehicle_no_required: 'License plate number is required',
      error_vehicle_reg_no_required: 'Vehicle Registration Number is required',
      error_document_id_required: 'Document ID is required',
      error_invalid_fax: 'Fax number is invalid',
      error_max_length_excced: 'Character max length exceed',
      error_email_id_required: 'Email is required',
      error_vehicle_make_required: 'Vehicle make is required',
      error_number_of_seats_required: 'Number of seats is required',
      error_invalid_number_of_seats: 'Invalid number of seats',
      error_additional_seat_fare_required: 'Additional seat fare is required',
      error_invalid_additional_seat_fare: 'Invalid additional seat fare',
      error_mode_required: 'Mode is required',
      error_usage_required: 'Usage is required',
      error_limit_of_uses_required: 'No of uses is required',
      error_company_required: 'Company is required',
      error_company_name: 'Company name is required',
      error_old_password_required: 'Old password is required',
      error_new_password_required: 'New password is required',
      error_new_confirm_password_required: 'Confirm new password is required',
      error_new_confirm_password_not_match: 'New and confirm password are not matched',
      error_account_confirm_account_not_match: 'Account number and confirm account number are not matched',
      error_similar_location_name: 'Pickup location and Drop location cannot be the same',
      error_from_date_required: 'From date is required',
      error_invalid_date: 'Invalid Date & time',
      error_quotation_amount: 'please enter quotation amount',
      error_quotation_note: 'Please enter quotation note',
      error_quotatin_file: 'please upload quotation',
      error_vehicle_type_required: 'Vehicle type is required',
      error_trip_type_required: 'Trip type is required',
      error_member_required: 'Patient id is required',
      error_appointment_type_required: 'Appointment type is required',
      error_select_one: 'Please select one',
      error_appointment_type_reason_required: 'Appointment reason is required',
      error_member_requirement_required: 'Please select one Patient',
      error_additional_note_required: 'Additional note is required',
      error_vehicle_name_required: 'Vehicle name is required',
      error_languageCode_required: 'Language is required',
      error_recurrence_week_max_min: 'Range must be 1 - 9',
      error_recurrence_max_min: 'Range must be 1 - 99',
      error_to_date_required: 'To date is required',
      error_invalid_access_group: 'No access group assigned to the user',
      error_fetch_meta_data: 'Error occured during fetching user meta data',
      error_invalid_email: 'Email is invalid',
      error_invalid_phone_number: 'Phone number is invalid',
      error_invalid_country_code: 'Country code is invalid',
      error_enroute_waiting_fare_required: 'Enroute waiting fare is required',
      error_invalid_enroute_waiting_fare: 'Enroute waiting fare is invalid',
      error_free_distance_required: 'Free distance is required',
      error_invalid_free_istance: 'Invalid free distance',
      error_source_radius_required: 'Source radius is required',
      error_invalid_source_radius: 'Source radius is invalid',
      error_destination_radius_required: 'Destination radius is required',
      error_invalid_destination_radius: 'Destination radius is invalid',
      error_ride_share_name_required: 'Ride share name is required',
      error_per_km_fare_required: 'Per km fare is required',
      error_invalid_per_km_fare: 'Invalid per km fare',
      error_per_miles_fare_required: 'Per miles fare is required',
      error_invalid_per_miles_fare: 'Invalid per miles fare',
      error_invalid_per_mile_mile_fare: 'Invalid per mile fare',
      error_per_minute_fare_required: 'Per min fare is required',
      error_invalid_per_minute_fare: 'Invalid per min fare',
      error_wait_time_fare_required: 'Wait time fare is required',
      error_invalid_wait_time_fare: 'Invalid wait time fare',
      error_cancellation_charges_required: 'Cancellation charges is required',
      error_invalid_cancellation_charges: 'Invalid cancellation charges',
      error_driver_percentage_required: 'Driver percentage is required',
      error_invalid_driver_percentage: 'Invalid driver percentage',
      error_minimum_fare_required: 'Minimum fare is required',
      error_invalid_minimum_fare: 'Invalid minimum fare',
      error_tour_time_fare_required: 'Trip time fare is required',
      error_invalid_tour_time_fare: 'Invalid trip time fare',
      error_vehicle_icon_required: 'Vehicle icon is required',
      error_role_type_required: 'Role type is required',
      error_status_required: 'Status is required',
      error_radius_required: 'Radius is required',
      error_radius_area_required: 'Radius area is required',
      error_description_required: 'Description is required',
      error_user_required: 'User is required',
      error_segment_list_required: 'Segment is required',
      error_message_type_required: 'Message type is required',
      error_message_title_required: 'Message type is required',
      error_message_description_required: 'Message description required',
      error_select_date_range: 'Please select date range',
      error_valid_parameter_to_view_export: 'Please select any valid parameter to view exported file',
      error_service_city_required: 'Service city is required',
      error_getting_platform_summery_data: 'Error occured during getting platform summary data',
      error_getting_heat_map_data: 'Error occured during getting heat map data',
      error_role_required: 'Role name is required',
      error_first_name_required: 'First name is required',
      error_last_name_required: 'Last name is required',
      error_tax_name_required: 'Tax name is required',
      error_tax_percentage_required: 'Tax percentage is required',
      error_invalid_tax_percentage: 'Invalid tax percentage',
      error_per_Mile_fare_required: 'Per mile fare is required',
      error_password_required: 'Password is required',
      error_company_name_required: 'Company name is required',
      error_company_website_required: 'Company website is required',
      error_password_pattern: `Password should be minimum 8 characters and must include a combination of
      uppercase and lowercase alphabets, symbols and numbers. Example: Test@1234`,
      error_invalid_company_website: 'Invalid company website',
      error_invalid_website_url: 'Invalid website URL',
      error_address_1_required: 'Address 1 is required',
      error_vehice_is_requied: 'Vehicle is required',
      error_not_exist_page: 'The page you are looking is not exist. Please contact to your Admin.',

      label_pu_addr :'PU Addr',
      label_do_addr :'DO Addr',
      label_pu_time_date :'PU Time',
      label_appt_time :'Appt time',
      label_estimated_distance_time:'Estimated Distance And Time',
      label_trip_by_appointment_type:'Trips by Appointment Type',
      label_import_data: 'Import Trip Data'
    });
  }
}
