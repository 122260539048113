import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInLayoutComponent } from './pages/sign-in-layout/sign-in-layout.component';
import { AuthenticationValidationService } from './modules/shared/services/authentication-validation.service';
import { TrackMyLocationComponent } from './pages/track-my-location/track-my-location.component';
import { ForgotPasswordComponent } from './modules/shared/components/forgot-password/forgot-password.component';
import { OtpVerifiyComponent } from './modules/shared/components/otp-verifiy/otp-verifiy.component';
import { SetPasswordComponent } from './modules/shared/components/set-password/set-password.component';
import { ComplaintComponent } from './pages/complaint/complaint.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'admin-dashboard',
    canActivate: [AuthenticationValidationService]
  },
  {
    path: 'signin',
    pathMatch: 'full',
    component: SignInLayoutComponent
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent
  },
  {
    path: 'otp-verify',
    pathMatch: 'full',
    component: OtpVerifiyComponent
  },
  {
    path: 'set-password',
    pathMatch: 'full',
    component: SetPasswordComponent
  },
  {
    path: 'trackmylocation',
    pathMatch: 'full',
    component: TrackMyLocationComponent
  },
  
  {
    path: 'admin-dashboard',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/admin-dashboard/admin-dashboard.module#AdminDashboardModule'
  },
  {
    path: 'trips',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/bookings/bookings.module#BookingsModule'
  },
  // static code start
  {
    path: 'billing',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/billing/billing.module#BillingModule'
  },

  {
    path: 'complaint-add',
    pathMatch: 'full',
    component: ComplaintComponent,
  },
  // static code end
  {
    path: 'emergency',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/emergency/emergency.module#EmergencyModule'
  },
  {
    path: 'scheduler',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/schedulers/schedulers.module#SchedulersModule'
  },
  {
    path: 'members',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/passengers/passengers.module#PassengersModule'
  },
  {
    path: 'providers',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/providers/providers.module#ProvidersModule'
  },
  {
    path: 'health-centers',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/health-centers/health-centers.module#HealthCentersModule'
  },
  {
    path: 'vehicles',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/vehicles/vehicles.module#VehiclesModule'
  },
  {
    path: 'drivers',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/drivers/drivers.module#DriversModule'
  },
  {
    path: 'manage-cities',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/manage-cities/manage-cities.module#ManageCitiesModule'
  },
  {
    path: 'manage-companies',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/manage-companies/manage-companies.module#ManageCompaniesModule'
  },
  {
    path: 'marketing',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/marketing/marketing.module#MarketingModule'
  },
  {
    path: 'app-settings',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/tenant-settings/tenant-settings.module#TenantSettingsModule'
  },
  {
    path: 'user-management',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/user-management/user-management.module#UserManagementModule'
  },
  {
    path: 'operator-management',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/operator-management/operator-management.module#OperatorManagementModule'
  },
  {
    path: 'settings',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/settings/settings.module#SettingsModule'
  },
  {
    path: 'reports',
    canActivate: [AuthenticationValidationService],
    loadChildren: './modules/reports/reports.module#ReportsModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
