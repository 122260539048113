
import { UserCommonService } from './../../services/user-common.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Message } from 'primeng/components/common/api';
import { ErrorBindingService } from '../../services/error-binding.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-otp-verifiy',
  templateUrl: './otp-verifiy.component.html'
})

export class OtpVerifiyComponent implements OnInit {
  @Input() email;
  @Input() phone;
  @Output() otpPopClose: EventEmitter<any> = new EventEmitter();
  @Output() otpPopSave: EventEmitter<any> = new EventEmitter();

  message: Message[] = [];
  otpForm = new FormGroup({
    otpInput: new FormControl()
  });

  otpErrors = [
    {
      otpInput: false
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private commonServices: UserCommonService,
    private errorBindingService: ErrorBindingService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.otpForm = this.formBuilder.group({
      otpInput: ['', [Validators.required]],
    });
  }

  closeEditForgot(event) {
    this.otpForm.reset();
    this.otpPopClose.emit();
  }

  onBackSign(event) {
    this.otpForm.reset();
    this.router.navigate(['signin']);
  }

  changePassword(event) {
    this.message = [];
    let input;
    const otpInput = this.otpForm.controls.otpInput.value;
    input = {
      otp: otpInput,
      email: this.commonServices.userEmail
    };

    this.commonServices.validateOtp(input).subscribe(results => {
      this.commonServices.setOtpValue(results.otpId);

      setTimeout(() => {
        this.router.navigate(['/set-password']);
        this.otpForm.reset();
        this.message = [];
      }, 1200);

    }, (error) => {
      this.showMessage('Error', error.general[0].message);
    });
  }

  showMessage(severity, message) {
    this.message = [];
    this.message.push({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

}
