import { UserCommonService } from './../../services/user-common.service';
import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { ErrorBindingService } from './../../services/error-binding.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Message } from 'primeng/components/common/message';
import { Router } from '@angular/router';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html'
})

export class SetPasswordComponent implements OnInit {
  @Output() isSetPasswordPopClose: EventEmitter<any> = new EventEmitter();
  @Input() otpId;
  message: Message[] = [];

  passwordError = [{
    message: false
  }];

  setPasswordForm = new FormGroup({
    newPassword: new FormControl(),
    newConfirmPassword: new FormControl()
  });

  constructor(private formBuilder: FormBuilder,
    private commonServices: UserCommonService,
    private commonService: CommonBindingDataService,
    private errorBindingService: ErrorBindingService,
    private router: Router, ) { }

  ngOnInit() {
    this.setPasswordForm = this.formBuilder.group({
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(25),
        Validators.pattern(AppSettings.PASSWORD_PATTERN)
      ]],
      newConfirmPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(25),
        Validators.pattern(AppSettings.PASSWORD_PATTERN)
      ]]
    });
  }

  passwordNotMatched() {
    if (this.setPasswordForm.controls.newPassword.value !== this.setPasswordForm.controls.newConfirmPassword.value) {
      const error = {
        general: [
          {
            message: this.commonService.getLabel('error_new_confirm_password_not_match')
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(JSON.stringify(error), this.passwordError);
    } else {
      return true;
    }
  }

  requestData() {
    return {
      otpId: this.commonServices.otpId,
      password: this.setPasswordForm.controls.newPassword.value,
    };
  }

  doChangePassword() {
    const passwordVerified = this.passwordNotMatched();
    if (passwordVerified) {
      const data = this.requestData();
      const c = this;
      this.commonServices.setPassword(data).subscribe(results => {
        if (results.general !== null && results.general !== undefined) {
          results.general.forEach(element => {
            this.showMessage('Success', results.general[0].message);
          });
          setTimeout(() => {
            this.router.navigate(['']);
            this.setPasswordForm.reset();
          }, 1200);
        }
      }, (error) => {
        if (error.general !== null && error.general !== undefined) {
          error.general.forEach(element => {
            this.showMessage('Error', error.general[0].message);
          });
        }
      });
    }
  }

  onCancel() {
    this.setPasswordForm.reset();
    this.isSetPasswordPopClose.emit(false);
  }

  onBackSign(event) {
    this.setPasswordForm.reset();
    this.router.navigate(['signin']);
  }

  showMessage(severity, message) {
    this.message = [];
    this.message.push({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

}
