// export const environment = {
//   production: false,
//   basePath: 'https://nemtdevapi.mi2.in/api',
//   tenant: 'T001',
//   supportedCountry: ['IN', 'UK', 'US'],
//   idleTimeEnable: true
// };


export const environment = {
  production: false,
  basePath: 'https://nemtdemoapi.mi2.in/api',
  tenant: 'T001',
  supportedCountry: ['IN', 'UK', 'US'],
  idleTimeEnable: true
};
