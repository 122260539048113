<app-loader></app-loader>
<div *ngIf="isReady" class="ui-g ui-g-nopad device-height">
  <app-header *ngIf="notificationService.canFetchNotification" class="top-nav ui-g-12 ui-g-nopad header-panel">
  </app-header>
  <div class="main-panel ui-g-12 ui-g-nopad">
    <app-sidebar *ngIf="notificationService.canFetchNotification" class="sidebar ui-g-12 ui-md-3 left-panel ui-g-nopad">
    </app-sidebar>

    <div class="right-panel">
      <app-page-header *ngIf="notificationService.canFetchNotification"></app-page-header>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>