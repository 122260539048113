
<div id="page_1">

<div class="dclr"></div>

<div class="dclr"></div>
<p class="p19 ft10">Today’s Date <span class="ft9">__________________________________</span>Facility/Agency Name __________________________________</p>
<p class="p20 ft9"><span class="ft10">Completed by </span>__________________________________ <span class="ft10">Title </span>___________________________________________</p>
<p class="p20 ft10">Phone Number _________________________ Fax Number ____________________________________________</p>
<p class="p20 ft10">Member’s Legal Name ________________________________________ Member ID Number __________________</p>
<p class="p19 ft10">Site of Complaint _______________________________________________________________________________</p>
<p class="p20 ft10">Has Ride Assist been contacted? N Y (<span class="ft11">If yes, this form is not required</span><span class="ft12">) </span>Complaint Number__________________</p>
<p class="p20 ft10">Trip Date _______________ Transportation Company Name ____________________________________________</p>
<p class="p19 ft13">Nature of Complaint:</p>
<p class="p20 ft14">Please Check All That Apply</p>
<p class="p21 ft15">
	<input type = "checkbox" />
	Facility/Agency/Member/Family not notified regarding transportation provider and/or schedule changes <br>
	<input type = "checkbox" /> Provider NO-SHOW (did not arrive)</p>
<table cellpadding=0 cellspacing=0 class="t0">
<tr height=0>
	<td width=151px></td>
	<td width=56px></td>
	<td width=387px></td>
</tr>
<tr>
	<td colspan=2 class="tr0 td0"><p class="p22 ft10"> <input type = "checkbox" /> Provider was LATE or EARLY</p></td>
	<td class="tr0 td1"><p class="p22 ft10">Scheduled Arrival Time _________ Actual Arrival Time ________</p></td>
</tr>
<tr>
	<td colspan=3 class="tr1 td2"><p class="p22 ft10"> <input type = "checkbox" />Missed appointment (provider was late causing the member to miss their appointment)</p></td>
</tr>
<tr>
	<td colspan=3 class="tr1 td2"><p class="p22 ft10"><input type = "checkbox" /> Incorrect vehicle type (wheelchair van requested, ambulatory vehicle sent)</p></td>
</tr>
<tr>
	<td colspan=3 class="tr1 td2"><p class="p22 ft10"> <input type = "checkbox" />No vehicle `available per LogistiCare</p></td>
</tr>
<tr>
	<td class="tr1 td3"><p class="p22 ft10"> <input type = "checkbox" /> Excessive travel time</p></td>
	<td colspan=2 class="tr1 td4"><p class="p22 ft10">Pick up time ____________ Arrival time ____________</p></td>
</tr>
<tr>
	<td colspan=3 class="tr0 td2"><p class="p22 ft10"> <input type = "checkbox" /> Driver failed to follow special instructions <span class="ft11">(please provide additional information below)</span></p></td>
</tr>
</table>
<p class="p23 ft10"> <input type = "checkbox" /> Driver safety (did not require seatbelt use, speeding, careless driving, eating, drinking, smoking, using cell</p>
<table cellpadding=0 cellspacing=0 class="t1">
<tr>
	<td class="tr1 td5"><p class="p22 ft10">phone/texting while driving, inappropriate conduct)</p></td>
	<td class="tr1 td6"><p class="p24 ft11"><span class="ft10">(</span>please provide additional information below)</p></td>
</tr>
<tr>
	<td class="tr0 td5"><p class="p22 ft10"> <input type = "checkbox" /> Driver issue (no name tag, driver rude, driver lost)</p></td>
	<td class="tr0 td6"><p class="p22 ft11">(please provide additional information below)</p></td>
</tr>
</table>
<p class="p25 ft16"> <input type = "checkbox" /> Passenger assistance level issue Please circle one (hand to hand, door to door, curb to curb) <br>
	<input type = "checkbox" />	Vehicle safety (no inspection sticker, broken window, bald tires, cleanliness, no signage on vehicle,</p>
<table cellpadding=0 cellspacing=0 class="t2">
<tr>
	<td class="tr0 td7"><p class="p22 ft10">no heat or A/C)</p></td>
	<td class="tr0 td8"><p class="p22 ft10">License plate number _____________________________</p></td>
</tr>
</table>
<p class="p26 ft10"> <input type = "checkbox" /> Wheelchair incident (not using 4 tie downs, no seat belt used, no shoulder restraint used, driver riding on wheelchair lift, lift not working properly) <span class="ft11">(please provide additional information below)</span></p>
<p class="p27 ft11"><span class="ft10"> <input type = "checkbox" /> Other issues </span>(please provide additional information below)</p>
<p class="p20 ft9">Additional Information_______________________________________________________________________</p>
<p class="p19 ft9">__________________________________________________________________________________________</p>
<p class="p20 ft9">__________________________________________________________________________________________</p>
</div>
<div style="
text-align: center;
"> <input type = "submit" value = "Submit"></div>







