import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Message } from 'primeng/components/common/api';
import { Router } from '@angular/router';
import { AppSettings } from '../../../shared/app.settings';
import { UserCommonService } from './../../services/user-common.service';
import { ErrorBindingService } from '../../services/error-binding.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})

export class ForgotPasswordComponent implements OnInit {
  @Output() popClose: EventEmitter<any> = new EventEmitter();
  @Output() popSave: EventEmitter<any> = new EventEmitter();

  forgotPasswordErrors = [
    {
      username: false
    }
  ];
  message: Message[] = [];
  editforgotForm = new FormGroup({
    username: new FormControl()
  });

  constructor(
    private formBuilder: FormBuilder,
    private commonServices: UserCommonService,
    private errorBindingService: ErrorBindingService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.editforgotForm = this.formBuilder.group({
      username: ['', [Validators.required]],
    });
  }

  closeEditForgot(event) {
    this.editforgotForm.reset();
    this.popClose.emit();
  }

  onBackSign(event) {
    this.editforgotForm.reset();
    this.router.navigate(['signin']);
  }

  changePassword(event) {
    this.message = [];
    let input;
    let otpEmail;
    let otpPhone;

    const userName = this.editforgotForm.controls.username.value.trim();
    if (userName.match(AppSettings.EMAIL_PATTERN)) {
      otpEmail = userName;
      input = {
        email: userName,
        tenantId: AppSettings.TENANT
      };
    } else if (userName.match(AppSettings.PHONE_PATTERN)) {
      otpPhone = userName;
      input = {
        phoneNum: userName,
        tenantId: AppSettings.TENANT
      };
    } else {
      input = {
        username: userName,
        tenantId: AppSettings.TENANT
      };
    }

    this.commonServices.forgotPassword(input).subscribe(results => {
      this.showMessage('Success', results.general[0].message);
      results.email = otpEmail;
      results.phone = otpPhone;
      setTimeout(() => {
        this.router.navigate(['/otp-verify']);
        this.editforgotForm.reset();
        this.message = [];
      }, 1200);
    }, (error) => {
      this.showMessage('Error', error.general[0].message);
    });
  }

  showMessage(severity, message) {
    this.message = [];
    this.message.push({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

}
